import { CurrencyService } from "services/currency-service";
import { nameof as nameof_ServiceCallQuotationItemType, ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { CatalogCostManagementMode } from "api/enums/catalog-cost-management-mode";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { DialogController } from "aurelia-dialog";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { ApiHelper } from "helpers/api-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemMaterialBase } from "pages/services/quotations/item-material-base";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { SettingRepository } from "repositories/setting-repository";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { Dialog } from "interfaces/dialog";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { CloneHelper } from "helpers/cloneHelper";
import { Position } from "enums/position";
import { CurrencyModel } from "api/models/company/currency-model";
import UserAccessService from "services/user-access-service";

export interface QuotationItemMaterialDialogParameters {
    quotationItem: ServiceCallQuotationItemModel;
    parentQuotationItem: ServiceCallQuotationItemModel | null;
    quotation: ServiceCallQuotationDetailsModel;
}

@autoinject()
export class QuotationItemMaterialDialog extends QuotationItemMaterialBase implements Dialog {

    public dialogHeaderTitle: string = "";
    public isUsingCurrency: boolean = false;

    public readonly Position: typeof Position = Position;
    public readonly ServiceCallQuotationItemType: typeof ServiceCallQuotationItemType = ServiceCallQuotationItemType;
    public readonly CatalogCostManagementMode: typeof CatalogCostManagementMode = CatalogCostManagementMode;

    @computedFrom("selectedItem")
    public get canShowCurrency(): boolean {
        if (!this.quotation || !this.quotationItem) { return false; }
        const condition1 = this.quotationItem.Type === ServiceCallQuotationItemType.Material && (this.quotationItem.CostManagementMode === CatalogCostManagementMode.Variable ||  this.quotationItem.CostManagementMode === CatalogCostManagementMode.Undefined);
        return this.userAccessService.isCurrencyOptionEnabledSync() && condition1;
    }

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        apiHelper: ApiHelper,
        settingRepository: SettingRepository,
        dispatchTemplateService: DispatchTemplateService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        currencyService: CurrencyService,
        private readonly userAccessService: UserAccessService,
        private readonly dialogController: DialogController,
        private readonly enumFormatValueConverter: EnumFormatValueConverter
    ) {
        super(validationControllerFactory, i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, validationHelper, validationController, apiHelper, settingRepository, dispatchTemplateService, serviceCallQuotationPriceService, currencyService);
    }

    public async activate(params: QuotationItemMaterialDialogParameters): Promise<void> {
        this.initQuotationItem(params.quotationItem);
        this.quotation = params.quotation;
        await this.initCatalogBaseUrl();

        this.dialogHeaderTitle =  this.enumFormatValueConverter.toView(this.quotationItem.Type, nameof_ServiceCallQuotationItemType);
    }

    public async ok(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        // Update linked items (only if the catalog item or the quantity was changed).
        if (this.quotationItem.Quantity !== this.originalQuantity || this.quotationItem.CatalogId !== this.selectedItem.id || this.quotationItem.Id === 0) {
            await this.updateLinkedItems();
        }

        this.quotationItem.CatalogId = this.selectedItem.id;

        if (this.isUsingCurrency && this.currency) {
            this.quotationItem.CurrencyCode = this.currency.Code;
            this.quotationItem.CurrencyRate = this.currencyRate;
            this.quotationItem.CurrencyUnitPrice = this.currencyUnitPrice;
        }

        this.dialogController.ok(this.quotationItem);
    }

    public cancel(): void {
        Object.assign(this.quotationItem, this.originalQuotationItem);
        this.dialogController.cancel();
    }

    private async initCurrency(): Promise<void> {
        this.isUsingCurrency = !!this.quotationItem.CurrencyCode;
        if (!this.isUsingCurrency) { return; }

        const currencies = await this.currencyService.GetQuotationCurrencies(this.quotationItem.QuotationId).then((result: CurrencyModel[]) => {
            return result;
        });

        const currentCurrency = currencies.find((currency: CurrencyModel) =>  currency.Code === this.quotationItem.CurrencyCode);
        this.currencyRate = this.quotationItem.CurrencyRate;
        this.currencyUnitPrice = this.quotationItem.CurrencyUnitPrice;

        if (currentCurrency) {
            this.currencySymbol = currentCurrency.Symbol ? currentCurrency.Symbol : "";
            this.currency = currentCurrency;
        }
    }

    private async  initQuotationItem(quotationItem: ServiceCallQuotationItemModel): Promise<void> {
        this.quotationItem = quotationItem;
        this.originalQuotationItem = CloneHelper.deepClone(quotationItem);
        this.calculationInProgress = true;
        this.profitMargin = this.quotationItem.ProfitMargin;
        this.unitCost = this.quotationItem.UnitCost;
        this.sellingPrice = this.quotationItem.UnitPrice;
        this.originalQuantity = this.quotationItem.Quantity;

        this.initCurrency();

        this.initValidation();
        this.initSelectedItem(this.quotationItem);
        this.calculationInProgress = false;
    }
}
