import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemEquipmentBase } from "pages/services/quotations/item-equipment-base";
import { EquipmentService } from "services/equipment-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { nameof as nameof_ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { Dialog } from "interfaces/dialog";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { CloneHelper } from "helpers/cloneHelper";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";

export interface QuotationItemEquipmentDialogParameters {
    quotationItem: ServiceCallQuotationItemModel;
    parentQuotationItem: ServiceCallQuotationItemModel | null;
    quotation: ServiceCallQuotationDetailsModel;
}

@autoinject()
export class QuotationItemEquipmentDialog extends QuotationItemEquipmentBase implements Dialog {

    public dialogHeaderTitle: string = "";

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        equipmentService: EquipmentService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        private readonly dialogController: DialogController,
        private readonly enumFormatValueConverter: EnumFormatValueConverter
    ) {
        super(validationControllerFactory, i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, equipmentService, validationHelper, validationController, serviceCallQuotationPriceService, serviceCallContractEquipmentService);
    }

    public activate(params: QuotationItemEquipmentDialogParameters): void {
        this.quotationItem = params.quotationItem;
        this.originalQuotationItem = CloneHelper.deepClone(params.quotationItem);
        this.calculationInProgress = true;
        this.profitMargin = this.quotationItem.ProfitMargin;
        this.sellingPrice = this.quotationItem.UnitPrice;
        this.calculationInProgress = false;
        this.quotation = params.quotation;
        this.dialogHeaderTitle =  this.enumFormatValueConverter.toView(this.quotationItem.Type, nameof_ServiceCallQuotationItemType);

        this.initValidation();
    }

    public async ok(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        this.dialogController.ok(this.quotationItem);
    }

    public cancel(): void {
        Object.assign(this.quotationItem, this.originalQuotationItem);
        this.dialogController.cancel();
    }
}
